import React from "react";
import { Link } from "react-router-dom";
import "../style/Hamburger.css";

class Hamburger extends React.Component {
  state = { clicked: false, services: false };

  toggleMenu = () => {
    this.setState({ clicked: !this.state.clicked, services: false });
  };

  createMenu = () => {
    return (
      <button className="burger-button" onClick={this.toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </button>
    );
  };

  renderServicesMenu = () => {
    const showServices = this.state.services ? "shown-h" : "hidden-h";
    const navContent = this.state.services ? "show" : "hide";
    const subitemTransition = this.state.services
      ? "subitem-show"
      : "subitem-hide";

    return (
      <div
        className={`sidebar-dropdown-container ${navContent} ${showServices}`}
      >
        <div className={`submenu ${showServices}`}>
          <Link
            to={"/malden"}
            onClick={this.toggleMenu}
            className={`submenu-item first ${subitemTransition} ${navContent}`}
          >
            Malden
          </Link>
          <Link
            to={"/boston"}
            onClick={this.toggleMenu}
            className={`submenu-item second ${subitemTransition} ${navContent} `}
          >
            Boston
          </Link>
        </div>
      </div>
    );
  };

  // state.clicked refers to if the hamburger menu has been touched
  createSidebar = () => {
    const navBar = this.state.clicked ? "shown" : "hidden";
    const navContent = this.state.clicked ? "show" : "hide";
    const fadeBackground = this.state.clicked ? "shown-full" : "hidden";

    return (
      <div className="container">
        <div className={`sidebar-container ${navBar}`}>
          <Link
            to={"/"}
            onClick={this.toggleMenu}
            className={`sidebar-content ${navContent}`}
          >
            Home
          </Link>
          <button
            onClick={() => this.setState({ services: !this.state.services })}
            className={`sidebar-content sidebar-button ${navContent}`}
          >
            Services
          </button>
          {this.renderServicesMenu()}
          <Link
            to={"/covid"}
            onClick={this.toggleMenu}
            className={`sidebar-content ${navContent}`}
          >
            Covid-19 info
          </Link>
          <a
            href="https://www.instagram.com/kn_nailspa/"
            onClick={this.toggleMenu}
            className={`sidebar-content ${navContent}`}
          >
            <i className=" fa fa-instagram" />
          </a>
        </div>
        <div
          onClick={this.toggleMenu}
          className={`dimmer ${fadeBackground}`}
        ></div>
      </div>
    );
  };

  render() {
    return (
      <div className="burger">
        {this.createMenu()}
        {this.createSidebar()}
      </div>
    );
  }
}

export default Hamburger;
