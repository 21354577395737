import React from "react";
import "../style/Address.css";

const Address = () => {
  return (
    <div className="address-bar">
      <div className="table-wrap">
        <div className="address-block">
          <div className="address-content">
            <h4>Addresses</h4>
            <p>
              <b>Wonder Nails</b>
              <br />
              26 Pleasant Street, <br /> Malden MA, 02148 <br />
              (781) 324-6606
            </p>
            <p>
              <b>Sue's Nails &amp; Spa</b>
              <br />
              646 Blue Hill Ave, <br /> Boston MA, 02121 <br />
              (617) 288-1781
            </p>
          </div>

          <div className="address-content">
            <h4>Contact</h4>
            <p>Have a question, comment, or a concern?</p>
            <p>
              E-mail us at <br />
              <b>info@knailsboston.com</b>
            </p>
          </div>
          <div className="address-content">
            <h4>Opening hours</h4>
            <p>
              Mon-Sat - 10:00am - 6:00pm <br />
              Sunday - Closed
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Address;
