import React from "react";
import Header from "./components/Header";
import Landing from "./components/Landing";
import { Router, Route, Switch } from "react-router-dom";
import history from "./history";
import Address from "./components/Address";
import Malden from "./components/Malden";
import Boston from "./components/Boston";
import CovidWarning from "./components/CovidWarning";

class App extends React.Component {
  state = { width: window.innerWidth, height: window.innerHeight };
  maxMobileSize = 900; // the header will change based on screen width

  componentDidMount() {
    window.addEventListener("resize", this.updateScreenSize);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateScreenSize);
  }

  updateScreenSize = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    return (
      <div>
        <Router history={history}>
          <Header width={this.state.width} maxMobileSize={this.maxMobileSize} />
          <Switch>
            <Route path="/" exact component={Landing} />
            <Route path="/malden" exact component={Malden} />
            <Route path="/boston" exact component={Boston} />
            <Route path="/covid" exact component={CovidWarning} />
            <Route component={Landing} />
          </Switch>
          <Address />
        </Router>
      </div>
    );
  }
}

export default App;
