import React from "react";
import { Link } from "react-router-dom";
import "../style/Landing.css";
import statement from "../Mission";

class Landing extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  intro() {
    return (
      <div className="flex">
        <div className="intro">
          <div className="logo">
            <div className="first logo-content">relax at our</div>
            <div className="logo-content">nail spas</div>
          </div>
          <p className="text general-font">{statement}</p>
          
        </div>
      </div>
    );
  }

  covidWarning() {
    return (
      <Link to={"/covid"} className="covid-warning general-font">
        find out how we're dealing with covid-<span className="number-font">19</span> here
      </Link>
    );
  }

  render() {
    return (
      <div>
        {this.intro()}
      </div>
    );
  }
}

export default Landing;
