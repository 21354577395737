import React from "react";
import "../style/Menu.css";

class Malden extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  pedicureMenu() {
    return (
      <div className="price-container">
        <div className="price-content">
          <div className="price-content-text">
            <div className="menu-border-malden" />
            <h1 className="top-menu-header">pedicure</h1>
          </div>
          <div className="price-content-text space-between add-margin-top menu-width">
            <p>basic</p>
            <p className="price">25</p>
          </div>
          <div className="price-content-text space-between underline add-margin-top menu-width">
            <p>spa</p>
            <p className="price">35</p>
          </div>
          <p className="price-content-text center-text">
            includes
            <br />
            *moisture mask
            <br />
            hot towel wrap
            <br />
            extra massage
          </p>
          <div className="price-content-text">
            <h1 className="mid-menu-header underline">nail extensions</h1>
          </div>
          <div className="price-content-text">
            <table className="content-table">
              <thead>
                <tr>
                  <th></th>
                  <th>full-set</th>
                  <th>refill</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="align-left table-font">acrylic</td>
                  <td>35+</td>
                  <td>25+</td>
                </tr>
                <tr>
                  <td className="align-left table-font">ombré</td>
                  <td>50+</td>
                  <td>25+</td>
                </tr>
                <tr>
                  <td className="align-left table-font">glow in the dark</td>
                  <td>45+</td>
                  <td>25+</td>
                </tr>
                <tr>
                  <td className="align-left table-font">glow ombré</td>
                  <td>50+</td>
                  <td>25+</td>
                </tr>
                <tr>
                  <td className="align-left table-font">overlay</td>
                  <td>25+</td>
                  <td>20+</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="price-content-text space-between underline add-margin-top menu-width">
            <p style={{ fontSize: "1.2rem" }}>*add gel polish</p>
            <p className="price" style={{ fontSize: "1rem" }}>
              10
            </p>
          </div>
          <div className="dip-with-tips-border add-margin-top menu-width">
            <div className="price-content-text space-between">
              <p className="important-text">dip powder</p>
              <p className="price" style={{ fontSize: "1.4rem", top: "4px" }}>
                35
              </p>
            </div>
            <div className="price-content-text space-between">
              <p className="important-text">with tips</p>
              <p className="price" style={{ fontSize: "1.4rem", top: "4px" }}>
                45
              </p>
            </div>
          </div>
          <p
            style={{ marginTop: "3em" }}
            className="price-content-text center-text"
          >
            dry manicure. dip powder is <br />
            odorless, lightweight, durable, <br />
            and includes calcium and vitamin e <br />
            for minimal damage and easy <br />
            removal. up to 3 weeks of wear.
          </p>
        </div>
      </div>
    );
  }

  extraMenu() {
    return (
      <div className="price-container">
        <div className="price-content">
          <div className="price-content-text">
            <div className="menu-border-malden" />
            <h1 className="top-menu-header">manicure</h1>
          </div>
          <div className="price-content-text space-between add-margin-top menu-width">
            <p>classic</p>
            <p className="price">15</p>
          </div>
          <div className="price-content-text space-between add-margin-top menu-width">
            <p>gel</p>
            <p className="price">25</p>
          </div>
          <div className="price-content-text">
            <h1 className="mid-menu-header underline">extra</h1>
          </div>
          <div className="price-content-text add-margin-top">
            <div className="tiny-prices">
              <table>
                <tbody>
                  <tr>
                    <td className="align-left">dip/acrylic removal</td>
                    <td>10</td>
                  </tr>

                  <tr>
                    <td className="align-left">soak off gel polish</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td className="align-left">cut down</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td className="align-left">nail repair</td>
                    <td>3</td>
                  </tr>
                  <tr>
                    <td className="align-left">shape (coffin / stilettos)</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td className="align-left">french / color tips</td>
                    <td>5</td>
                  </tr>
                  <tr>
                    <td className="align-left">rhinestones</td>
                    <td>3+</td>
                  </tr>
                  <tr>
                    <td className="align-left">nail art</td>
                    <td>3+</td>
                  </tr>
                  <tr>
                    <td className="align-left">chrome gel</td>
                    <td>20</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="price-content-text">
            <h1 className="mid-menu-header underline">color change</h1>
          </div>
          <div className="price-content-text add-margin-top"></div>
          <div className="price-content-text add-margin-top">
            <div className="tiny-prices">
              <table>
                <tbody>
                  <tr>
                    <td className="align-left">regular hand</td>
                    <td>8</td>
                  </tr>
                  <tr>
                    <td className="align-left">regular feet</td>
                    <td>10</td>
                  </tr>
                  <tr>
                    <td className="align-left">gel polish hand</td>
                    <td>15</td>
                  </tr>
                  <tr>
                    <td className="align-left">gel polish feet</td>
                    <td>20</td>
                  </tr>
                  <tr>
                    <td className="align-left">*feet includes washing</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="center">
        <div className="store-name">wonder nails</div>
        <div className="service-container">
          <div className="service-container-wrap">
            {this.pedicureMenu()}
            {this.extraMenu()}
          </div>
        </div>
      </div>
    );
  }
}

export default Malden;
