import React from "react";
import { Link } from "react-router-dom";
import Hamburger from "../components/Hamburger";
import "../style/Header.css";

class Header extends React.Component {
  displayWideScreen = () => {
    return (
      <div>
        <div className="container-right">
          <Link to={"/"} className="button">
            Home
          </Link>
          <div className="dropdown">
            <button className="button">Services</button>
            <div className="dropdown-content">
              <Link to={"/malden"} className="button">
                Malden
              </Link>
              <Link to={"/boston"} className="button">
                Boston
              </Link>
            </div>
          </div>
          <a href="https://www.instagram.com/kn_nailspa/" className="button">
            Instagram
            <span>
              <i className="fa fa-instagram" />
            </span>
          </a>
        </div>
      </div>
    );
  };

  displayContent = () => {
    if (this.props.width <= this.props.maxMobileSize) {
      return (
        <div className="container-left">
          <Hamburger />
        </div>
      );
    } else return this.displayWideScreen();
  };

  render() {
    return <div className="header">{this.displayContent()}</div>;
  }
}

export default Header;
