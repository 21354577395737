import React from "react";
import "../style/Covid.css";

class CovidWarning extends React.Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="covid-container">
        <div className="covid-content">
          <h1>
            how we've adapted to covid-<span id="covid-number-font">19</span>
          </h1>
          <p>
            As you are all facing new challenges and adapting to the current
            pandemic, we are as well. To maintain the safety of our clients and
            employees, we ask you to please read and follow these guidelines at
            our storefronts to ensure that everyone remains safe and healthy.
            We're in this together. We appreciate your understanding!
          </p>
          <hr />
          <h1>guidelines</h1>
          <ul>
            <li>
              All clients taken will be <b>by appointment only.</b> You can find
              our contact information at the bottom of the page. No group
              appointments are accepted. <b>Everyone must book individually </b>
              even if you come as a group.
            </li>
            <li>
              <b>No waiting inside of the salon.</b> After making an
              appointment, please wait outside until you are notified. No
              accompanying person is allowed in the salon.
              <b> Only those getting serviced</b> are allowed to enter. An adult
              may accompany a minor client (under 16).
            </li>
            <li>
              Due to extra expenses to maintain the safety of our clients,
              <b> a $5 charge per client</b> will be added to your bill.
            </li>
            <li>
              All clients upon entering <b>must wear a mask at all times. </b>
              If you do not have one, we will provide one.
            </li>
            <li>
              All clients <b>must stay 6 feet away</b> from others when
              possible.
            </li>
            <li>No cellphone use during services.</li>
            <li>
              <b>Before all services,</b> all clients must wash their hands.
            </li>
          </ul>
        </div>
      </div>
    );
  }
}

export default CovidWarning;
